/**
 * Enums
 * Keep this file in sync with the backend
 */

const enum BinaryOption {
  YES = 'yes',
  NO = 'no',
}

const enum TernaryOption {
  YES = 'yes',
  NO = 'no',
  PARTLY = 'partly',
}

const enum SignCodeStatusError {
  EXPIRED = 'expired',
  INVALID = 'invalid',
  BAD_PASSWORD = 'badPassword',
  UNKNOWN = 'unknown',
}

enum Difficulty {
  BLANK = 'blank',
  EASY = 'easy',
  MEDIUM = 'medium',
  DIFFICULT = 'difficult',
  MIXED = 'mixed',
}

const enum DifficultyType {
  ECA = 'eca',
  INTERNATIONAL = 'international',
}

const enum PageCodeStatus {
  NOT_FOUND = '404',
}

const enum PageSize {
  NORMAL = 20,
  MEDIUM = 50,
  LARGE = 100,
}

const enum ExamAnswerArea {
  SEPARATE = 'separate',
  INLINE = 'inline',
}

const enum UserPermissions {
  ViewAdminUI = 'VIEW_ADMIN_UI',
  ViewAllContent = 'VIEW_ALL_CONTENT',
  EditQuestions = 'EDIT_QUESTIONS', // Frontend only for now
  EditAllQuestions = 'EDIT_ALL_QUESTIONS',
  EditAllCurricula = 'EDIT_ALL_CURRICULA',
  EditUserRoles = 'EDIT_USER_ROLES',
  ViewMetadata = 'VIEW_METADATA',
  MasqueradeAsAnyUser = 'MASQUERADE_AS_ANY_USER',
}

const enum QuestionEditMode {
  EDIT = 'edit',
  REPLACE = 'replace',
  COPY = 'copy',
  NEW = 'new',
}

enum QuestionPointsMode {
  POINTS_TOTAL = 'POINTS_TOTAL',
  POINTS_DIFFICULTY = 'POINTS_DIFFICULTY',
}

const enum AssessmentPointsMode {
  MATRIX_SUBCHAPTER = 'MATRIX_SUBCHAPTER', // a.k.a momentmatris
  MATRIX_ABILITY = 'MATRIX_ABILITY', // a.k.a förmågematris, not supported in KM2 yet
  POINTS_TOTAL = 'POINTS_TOTAL',
}

const enum AssessmentMode {
  TEACHER = 'teacher',
  STUDENT = 'student',
  FEEDBACK = 'feedback',
}

const enum QuestionPointsFormat {
  POINT = 'point',
  DIFFICULTY = 'difficulty',
  NONE = 'none',
}

const enum SearchVisibility {
  ALL = 'all',
  PUBLIC = 'public',
  OWN = 'own',
  NON_PUBLIC = 'nonPublic',
  PRACTICE = 'practice',
}

const enum ExamStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DISCOVERABLE = 'DISCOVERABLE',
}

const enum ExamAutomarkedStatus {
  FULLY = 'automarked',
  PARTIALLY = 'partially-automarked',
  NONE = 'not-automarked',
}

const enum ExamMovePosition {
  BEFORE = 'before',
  AFTER = 'after',
}

const enum ExamViewMode {
  DIGITAL = 'digital',
  PRINT = 'print',
}

const enum PrintPointsPer {
  QUESTION = 'question',
  SUB_QUESTION = 'subQuestion',
  NONE = 'none',
}

const enum ExamsCategory {
  NATIONAL = 'NATIONALEXAM',
  DIGITAL = 'DIGITALEXAM',
  COURSE = 'COURSEEXAM',
  TEACHIQ = 'TEACHIQEXAM',
  PUBLISHED = 'published', // BE has this with small letters
  DIAMOND = 'DIAMONDEXAM',
  NCM = 'NCMEXAM',
  OTHER = 'OTHER',
}

enum DigitalToolsMode {
  FULL_EXAM = 'FULL_EXAM',
  PART_SETTINGS = 'PART_SETTINGS',
  NONE = 'NONE',
}

const enum BrowserSecurity {
  UNLOCK_INSTANT = 'UNLOCK_INSTANT',
  LOCKTIME_30 = 'LOCKTIME_30',
  LOCKTIME_60 = 'LOCKTIME_60',
  LOCKTIME_90 = 'LOCKTIME_90',
  UNLOCK_MANUAL = 'UNLOCK_MANUAL',
  EXAM_LOG = 'EXAM_LOG',
}

const enum GeogebraType {
  CLASSIC = 'CLASSIC',
  SUITE = 'SUITE',
  GRAPHING = 'GRAPHING',
  GEOMETRY = 'GEOMETRY',
  CAS = 'CAS',
}

const enum ExamPartStatus {
  OPEN = 'OPEN',
  HIDDEN_UNTIL_STUDENT_SUBMIT = 'HIDDEN_UNTIL_STUDENT_SUBMIT',
  HIDDEN_UNTIL_TEACHER_APPROVE = 'HIDDEN_UNTIL_TEACHER_APPROVE',
  CLOSED = 'CLOSED',
}

const enum ExamPartStatusAfterSubmission {
  HIDDEN = 'HIDDEN',
  READONLY = 'READONLY',
}

const enum TranslationType {
  NONE = 'NONE',
  WORD = 'WORD',
  SENTENCE = 'SENTENCE',
}

enum SpellCheckLanguage {
  NONE = '0',
  SWEDISH = 'sv_SE',
  ENGLISH = 'en_US',
  DANISH = 'da_DK',
  FRENCH = 'fr_FR',
  ITALIAN = 'it_IT',
  DUTCH = 'nl_NL',
  NORWEGIAN_BOKMAL = 'nb_NO',
  NORWEGIAN = 'nn_NO',
  PORTUGUESE = 'pt_PT',
  SPANISH = 'es_ES',
  GERMAN = 'de_DE',
}

const enum ExamClient {
  ONLY_SEB = 'ONLY_SEB',
  NO_SEB = 'NO_SEB',
  PREFER_SEB = 'PREFER_SEB',
}

const enum AnswerAreaCode {
  DEFAULT = '0',
  NONE = '99',
  ANSWER_STANDARD = '1',
  MOTIVATION_NORMAL = '2',
  MOTIVATION_ANSWER = '3',
  ANSWER_LONG = '4',
  MOTIVATION_SMALL = '5',
  MOTIVATION_LARGE = '6',
  MOTIVATION_DOTTED = '7',
  MOTIVATION_DOTTED_LARGE = '8',
  MOTIVATION_GRID = '17',
  MOTIVATION_GRID_LARGE = '18',
  EXTRA_SPACE_3 = '9',
  EXTRA_SPACE_6 = '10',
  EXTRA_SPACE_12 = '12',
}

const enum AssessmentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  NOT_STARTED = 'NOT_STARTED',
}

const enum AssessmentSource {
  TEACHER = 'TEACHER',
  PARALLEL = 'PARALLEL',
  STUDENT = 'STUDENT',
}

const enum AssessmentCommentType {
  ASSESSMENT = 'Assessment',
  SUBQUESTION = 'AssessmentOfSubQuestion',
}

const enum AssessmentSortField {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  QUESTIONS = 'questionsDone',
  POINTS = 'pointsGiven',
  SELF_POINTS = 'pointsGivenByStudent',
}

const enum TabMenuValue {
  OVERVIEW = 'OVERVIEW',
  DIGITAL = 'DIGITAL',
  PRINT = 'PRINT',
  SETTINGS = 'SETTINGS',
  SUMMARY = 'SUMMARY',
  ATTACHMENTS = 'ATTACHMENTS',
  EXAMS = 'EXAMS',
  STUDENTS = 'STUDENTS',
  ACTIVITY = 'ACTIVITY',
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
  EXISTING = 'EXISTING',
  MONITORING = 'MONITORING',
  ASSESSMENT = 'ASSESSMENT',
  PUBLISH = 'PUBLISH',
  GRADING = 'GRADING',
  POINTS = 'POINTS',
  ABILITIES = 'ABILITIES',
  SUBCHAPTERS = 'SUBCHAPTERS',
  PRACTICE = 'PRACTICE',
}

const enum AssessmentPublishPreset {
  ALL = 'ALL',
  EXAM = 'EXAM',
  RESULT = 'RESULT',
}

const enum AssessmentPublishStatus {
  NO = 'NO',
  YES = 'YES',
  UNPUBLISHED = 'UNPUBLISHED',
}

const enum UserType {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
}

const enum UserOrganizationRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
}

const enum AssessmentAttachmentType {
  drawing = 'DRAWING',
  photo = 'PHOTO',
  geogebra = 'GEOGEBRA',
  desmos = 'DESMOS',
  scanning = 'SCANNING',
  programming = 'PROGRAMMING',
  audio = 'AUDIO',
}

/**
 * Constants
 */

const EXAM_DRAFT_ID = 'draft'
const FIRST_PAGE_INDEX = 0
const SAVE_DELAY = 1000
const TOAST_LIFETIME = 3000
const SORT_BY_NAME_KEY = 'name-desc'
const SORT_BY_RELEVANCE_KEY = 'sort-asc'

/**
 * Arrays
 */

// Assuming always three levels of difficulty to match BE pointTypes ['E', 'C', 'A']
const DIFFICULTY_LEVELS = [
  Difficulty.EASY,
  Difficulty.MEDIUM,
  Difficulty.DIFFICULT,
]
Object.freeze(DIFFICULTY_LEVELS)

const PAGE_SIZE_OPTIONS = [PageSize.NORMAL, PageSize.MEDIUM, PageSize.LARGE]
Object.freeze(PAGE_SIZE_OPTIONS)

const SEARCH_VISIBILITY_OPTIONS = [
  {
    id: SearchVisibility.ALL,
    name: 'questionBank.filter.visibility.all',
  },
  {
    id: SearchVisibility.PUBLIC,
    name: 'questionBank.filter.visibility.public',
  },
  {
    id: SearchVisibility.OWN,
    name: 'questionBank.filter.visibility.own',
  },
  {
    id: SearchVisibility.NON_PUBLIC,
    name: 'questionBank.filter.visibility.nonPublic',
    permission: UserPermissions.ViewAllContent,
  },
]
Object.freeze(SEARCH_VISIBILITY_OPTIONS)

const enum ExamType {
  Exam = 'EXAM',
  Assessment = 'ASSESSMENT',
  PracticeAssignment = 'PRACTICE_ASSIGNMENT',
  Evaluation = 'EVALUATION',
  Quiz = 'QUIZ',
  HomeworkTest = 'HOMEWORK_TEST',
}

const EXAM_TYPES = new Map<ExamType, object>()

EXAM_TYPES.set(ExamType.Exam, { kmId: 1 })
EXAM_TYPES.set(ExamType.Assessment, { kmId: 2 })
EXAM_TYPES.set(ExamType.PracticeAssignment, { kmId: 3 })
EXAM_TYPES.set(ExamType.Evaluation, { kmId: 4 })
EXAM_TYPES.set(ExamType.Quiz, { kmId: 5 })
EXAM_TYPES.set(ExamType.HomeworkTest, { kmId: 6 })
Object.freeze(EXAM_TYPES)

function getExamTypeTranslationKey(typeName?: string): string {
  if (!typeName) {
    return 'generic.unknown'
  }
  return `generic.examType.${typeName.toLowerCase()}`
}

/**
 * Help functions
 */

function getBinaryOptionTranslationKey(tab: BinaryOption): string {
  switch (tab) {
    case BinaryOption.YES:
      return 'generic.yes'
    case BinaryOption.NO:
      return 'generic.no'
    default:
      return 'generic.unknown'
  }
}

function getTernaryOptionTranslationKey(tab: TernaryOption): string {
  switch (tab) {
    case TernaryOption.YES:
      return 'generic.yes'
    case TernaryOption.NO:
      return 'generic.no'
    case TernaryOption.PARTLY:
      return 'generic.partly'
    default:
      return 'generic.unknown'
  }
}

function getDifficultyTranslationKey(label: Difficulty): string {
  switch (label) {
    case Difficulty.EASY:
      return 'grading.level.easy'
    case Difficulty.MEDIUM:
      return 'grading.level.medium'
    case Difficulty.DIFFICULT:
      return 'grading.level.difficult'
    case Difficulty.MIXED:
      return 'grading.level.mixed'
    default:
      return 'generic.unknown'
  }
}

function getAnswerAreaTypeTranslationKey(label: AnswerAreaCode): string {
  switch (label) {
    case AnswerAreaCode.DEFAULT:
      return 'answerAreaType.default'
    case AnswerAreaCode.NONE:
      return 'answerAreaType.none'
    case AnswerAreaCode.ANSWER_STANDARD:
      return 'answerAreaType.standard'
    case AnswerAreaCode.MOTIVATION_NORMAL:
      return 'answerAreaType.motivationNormal'
    case AnswerAreaCode.MOTIVATION_ANSWER:
      return 'answerAreaType.motivationAnswer'
    case AnswerAreaCode.ANSWER_LONG:
      return 'answerAreaType.long'
    case AnswerAreaCode.MOTIVATION_SMALL:
      return 'answerAreaType.motivationSmall'
    case AnswerAreaCode.MOTIVATION_LARGE:
      return 'answerAreaType.motivationLarge'
    case AnswerAreaCode.MOTIVATION_DOTTED:
      return 'answerAreaType.motivationDotted'
    case AnswerAreaCode.MOTIVATION_DOTTED_LARGE:
      return 'answerAreaType.motivationDottedLarge'
    case AnswerAreaCode.MOTIVATION_GRID:
      return 'answerAreaType.motivationGrid'
    case AnswerAreaCode.MOTIVATION_GRID_LARGE:
      return 'answerAreaType.motivationGridLarge'
    case AnswerAreaCode.EXTRA_SPACE_3:
      return 'answerAreaType.extraSpace3'
    case AnswerAreaCode.EXTRA_SPACE_6:
      return 'answerAreaType.extraSpace6'
    case AnswerAreaCode.EXTRA_SPACE_12:
      return 'answerAreaType.extraSpace12'
    default:
      return 'answerAreaType.default'
  }
}

function isEnumValue<E extends object>(
  enumObj: E,
  value: unknown
): value is E[keyof E] {
  return Object.values(enumObj).includes(value as E[keyof E])
}

export {
  isEnumValue,
  EXAM_TYPES,
  EXAM_DRAFT_ID,
  FIRST_PAGE_INDEX,
  SORT_BY_NAME_KEY,
  SORT_BY_RELEVANCE_KEY,
  SAVE_DELAY,
  TOAST_LIFETIME,
  DIFFICULTY_LEVELS,
  PAGE_SIZE_OPTIONS,
  SEARCH_VISIBILITY_OPTIONS,
  DigitalToolsMode,
  BrowserSecurity,
  GeogebraType,
  ExamPartStatus,
  ExamPartStatusAfterSubmission,
  TranslationType,
  SpellCheckLanguage,
  ExamClient,
  BinaryOption,
  TernaryOption,
  SignCodeStatusError,
  Difficulty,
  DifficultyType,
  ExamType,
  ExamViewMode,
  PageSize,
  PageCodeStatus,
  ExamAnswerArea,
  UserPermissions,
  QuestionEditMode,
  QuestionPointsMode,
  AssessmentPointsMode,
  AssessmentMode,
  QuestionPointsFormat,
  SearchVisibility,
  ExamStatus,
  ExamAutomarkedStatus,
  ExamMovePosition,
  PrintPointsPer,
  ExamsCategory,
  AnswerAreaCode,
  AssessmentAttachmentType,
  AssessmentStatus,
  AssessmentSource,
  AssessmentCommentType,
  AssessmentSortField,
  TabMenuValue,
  AssessmentPublishPreset,
  AssessmentPublishStatus,
  getBinaryOptionTranslationKey,
  getTernaryOptionTranslationKey,
  getDifficultyTranslationKey,
  getExamTypeTranslationKey,
  getAnswerAreaTypeTranslationKey,
  UserType,
  UserOrganizationRole,
}
