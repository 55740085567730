/**
 * V2 types
 */
var TYPES;
(function (TYPES) {
    TYPES["INFORMATION_BLOCK"] = "informationBlock";
    TYPES["ATTACHMENT_BLOCK"] = "attachmentBlock";
    TYPES["SIMPLE_ANSWER"] = "simpleAnswer";
    TYPES["MULTIPLE_CHOICE"] = "multipleChoice";
    TYPES["FILL_GAPS"] = "fillGaps";
    TYPES["FREE_TEXT"] = "freeText";
    TYPES["MATCH_ITEMS"] = "matchItems";
    TYPES["FORMATED_INPUT"] = "formatedInput";
    TYPES["FLEX_INPUT"] = "flexInput";
    TYPES["MARK_ON_IMAGE"] = "markOnImage";
    TYPES["NAME_IMAGE"] = "nameImage";
    TYPES["CATEGORIZING_ITEMS"] = "categorizingItems";
})(TYPES || (TYPES = {}));
export function v2ToV3(primaryId, questionV2Frozen) {
    const question = {
        id: primaryId,
        subQuestions: [],
        gradingRules: {},
        answers: {},
    };
    const questionV2 = JSON.parse(JSON.stringify(questionV2Frozen));
    questionV2.forEach((subQuestionV2) => {
        const id = subQuestionV2.id;
        question.subQuestions.push(extractSubQuestion(subQuestionV2));
        question.gradingRules[id] = extractGradingRules(primaryId, subQuestionV2);
        question.answers[id] = extractAnswers(subQuestionV2);
    });
    return question;
}
function extractSubQuestion(subQuestionV2) {
    return {
        id: subQuestionV2.id,
        subNumber: subQuestionV2.number,
        type: subQuestionV2.type,
        question: extractQuestion(subQuestionV2),
        answerOptions: extractAnswerOptions(subQuestionV2),
        formatting: extractFormatting(subQuestionV2),
        settings: extractSettings(subQuestionV2),
        point: convertPoint(subQuestionV2),
    };
}
function extractQuestion(subQuestionV2) {
    let question = '';
    switch (subQuestionV2.type) {
        case TYPES.FILL_GAPS: {
            const dummy = document.createElement('div');
            dummy.innerHTML = subQuestionV2.data.question;
            const gaps = Array.from(dummy.querySelectorAll('[data-gap~="true"]'));
            gaps.forEach((gap) => {
                gap.outerHTML = `<input type="text" spellcheck="false" data-gap="true" data-id="${gap.dataset.id}" class="fillGapInputField">`;
            });
            question = dummy.innerHTML;
            break;
        }
        default:
            question = subQuestionV2.data.question || '';
    }
    return question;
}
function convertPoint(subQuestionV2) {
    var _a, _b, _c, _d;
    if (!((_b = (_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a.criterias) === null || _b === void 0 ? void 0 : _b.length)) {
        return 0;
    }
    return ((_d = (_c = subQuestionV2.data) === null || _c === void 0 ? void 0 : _c.criterias) === null || _d === void 0 ? void 0 : _d.length) || 0;
}
function extractFormatting(subQuestionV2) {
    var _a, _b;
    const formatting = {
        answerArea: subQuestionV2.data.answerArea || null,
    };
    if ((_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a.toolbar) {
        formatting.toolbar = (_b = subQuestionV2.data) === null || _b === void 0 ? void 0 : _b.toolbar;
    }
    switch (subQuestionV2.type) {
        case TYPES.MULTIPLE_CHOICE:
            formatting.previewType = subQuestionV2.data.previewType || 'checkbox';
            break;
    }
    return formatting;
}
function extractSettings(subQuestionV2) {
    var _a;
    const settings = ((_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a.settings) || {};
    if (typeof subQuestionV2.data.autoGrade === 'boolean') {
        settings.autoGrade = subQuestionV2.data.autoGrade;
    }
    switch (subQuestionV2.type) {
        case TYPES.FREE_TEXT:
            settings.autoGrade = false;
            break;
    }
    return settings;
}
function extractAnswers(subQuestionV2) {
    var _a;
    const type = subQuestionV2.type;
    const answerKey = `${type}Answer`;
    const answerV2 = ((_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a[answerKey]) || null;
    let answers = null;
    switch (type) {
        case TYPES.SIMPLE_ANSWER:
            answers = answerV2.map((ans) => ans);
            break;
        case TYPES.MULTIPLE_CHOICE:
            answers = answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.filter((ans) => ans.isCorrect === true).map((ans) => ans.id);
            break;
        case TYPES.FILL_GAPS:
            answers = {};
            answerV2.forEach((ans) => {
                answers[ans.id] = ans.answer;
            });
            break;
        case TYPES.FREE_TEXT:
            answers = answerV2;
            break;
        case TYPES.MATCH_ITEMS:
            answers = {};
            answerV2.forEach((ans) => {
                if (!answers[ans.id]) {
                    answers[ans.id] = [];
                }
                answers[ans.id].push(ans.idRight);
            });
            break;
        case TYPES.FORMATED_INPUT:
            answers = answerV2;
            break;
        case TYPES.FLEX_INPUT:
            answers = answerV2;
            break;
        case TYPES.CATEGORIZING_ITEMS:
            answers = {};
            answerV2.forEach((ans) => {
                answers[ans.id] = ans.categories;
            });
            break;
        case TYPES.MARK_ON_IMAGE:
            answers = {};
            answerV2.forEach((ans) => {
                answers[ans.id] = {
                    x: ans.x,
                    y: ans.y,
                    height: ans.height,
                    width: ans.width,
                };
            });
            break;
        case TYPES.NAME_IMAGE:
            answers = {};
            answerV2.forEach((ans) => {
                if (!answers[ans.id]) {
                    answers[ans.id] = [];
                }
                answers[ans.id].push(ans.content);
            });
    }
    return answers;
}
function extractAnswerOptions(subQuestionV2) {
    var _a, _b, _c, _d, _e, _f, _g;
    const type = subQuestionV2.type;
    const answerKey = `${type}Answer`;
    const answerV2 = ((_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a[answerKey]) || null;
    let answerOptions = null;
    switch (type) {
        case TYPES.MULTIPLE_CHOICE:
            answerOptions = answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => {
                return {
                    id: ans.id,
                    label: ans.label,
                };
            });
            break;
        case TYPES.MATCH_ITEMS: {
            answerOptions = {
                left: (answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => ({
                    id: ans.id,
                    label: ans.left,
                }))) || [],
                right: answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => ({
                    id: ans.idRight,
                    label: ans.right,
                })),
            };
            (((_b = subQuestionV2.data) === null || _b === void 0 ? void 0 : _b.blindMatchItemsAnswer) || []).forEach((ans) => {
                if (ans.side === 'left') {
                    answerOptions.left.push({
                        id: ans.id,
                        label: ans.label,
                    });
                }
                else {
                    answerOptions.right.push({
                        id: ans.id,
                        label: ans.label,
                    });
                }
            });
            break;
        }
        case TYPES.FORMATED_INPUT:
            answerOptions = {
                input: ((_c = subQuestionV2.data) === null || _c === void 0 ? void 0 : _c.input) || '',
            };
            break;
        case TYPES.FLEX_INPUT:
            answerOptions = Object.assign({}, (((_d = subQuestionV2.data) === null || _d === void 0 ? void 0 : _d.inputFormat) || {}));
            break;
        case TYPES.CATEGORIZING_ITEMS:
            answerOptions = {
                options: (answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => ({
                    id: ans.id,
                    label: ans.content,
                }))) || [],
                categories: ((_e = subQuestionV2.data) === null || _e === void 0 ? void 0 : _e.categories) || [],
            };
            break;
        case TYPES.MARK_ON_IMAGE:
            answerOptions = {
                options: (answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => ({
                    id: ans.id,
                    label: ans.content,
                }))) || [],
                image: Object.assign({}, (_f = subQuestionV2.data) === null || _f === void 0 ? void 0 : _f.image),
            };
            break;
        case TYPES.NAME_IMAGE:
            answerOptions = {
                options: (answerV2 === null || answerV2 === void 0 ? void 0 : answerV2.map((ans) => ({
                    id: ans.id,
                    x: ans.x,
                    y: ans.y,
                    height: ans.height,
                    width: ans.width,
                }))) || [],
                image: Object.assign({}, (_g = subQuestionV2.data) === null || _g === void 0 ? void 0 : _g.image),
            };
            break;
    }
    return answerOptions;
}
function extractGradingRules(primaryId, subQuestionV2) {
    var _a, _b, _c, _d;
    const type = subQuestionV2.type;
    const ruleLesstypes = {
        [TYPES.SIMPLE_ANSWER]: true,
        [TYPES.FREE_TEXT]: true,
        [TYPES.FORMATED_INPUT]: true,
    };
    let gradingRules = [];
    if (ruleLesstypes[type] === true) {
        gradingRules =
            ((_b = (_a = subQuestionV2.data) === null || _a === void 0 ? void 0 : _a.criterias) === null || _b === void 0 ? void 0 : _b.map((criterion, index) => ({
                id: `${primaryId}_${subQuestionV2.id}_${index}`,
                wrong: 0,
                correct: -1,
                point: 1,
                label: (criterion === null || criterion === void 0 ? void 0 : criterion.comment) || 'Translation not implemented yet',
            }))) || [];
    }
    else {
        gradingRules =
            ((_d = (_c = subQuestionV2.data) === null || _c === void 0 ? void 0 : _c.gradingRules) === null || _d === void 0 ? void 0 : _d.map((rule, index) => {
                var _a, _b;
                return ({
                    id: `${primaryId}_${subQuestionV2.id}_${index}`,
                    wrong: rule.wrong,
                    correct: rule.correct,
                    point: 1,
                    label: ((_b = (_a = subQuestionV2.data.criterias) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.comment) ||
                        'Translation not implemented yet',
                });
            })) || [];
    }
    return gradingRules;
}
// If the questions is part of an exam with setting to have answers on separate paper
// apply this method to get the right question type in exam.net
export function convertQuestionToBeAnsweredSeparately(questionV3) {
    questionV3.subQuestions = questionV3.subQuestions.map((subQuestionV3) => {
        // Manually graded simpleAnswer and freeText with answers separately needs to be converted to attachmentBlock
        if ((subQuestionV3.type === TYPES.SIMPLE_ANSWER ||
            subQuestionV3.type === TYPES.FREE_TEXT) &&
            subQuestionV3.settings.autoGrade === false) {
            subQuestionV3.type = TYPES.ATTACHMENT_BLOCK;
            subQuestionV3.answerOptions = null;
            subQuestionV3.formatting = {
                autoGrade: false,
                answerArea: null,
            };
            questionV3.answers[subQuestionV3.id] = null;
        }
        return subQuestionV3;
    });
    return questionV3;
}
