import type { subQuestionV2, answerArea } from '../questionConverter'
import { getAnswerAreaTypes } from '@/utils/getAnswerAreaTypes'
import { AnswerAreaCode } from '@/constants'

export type MetaAnswerAreaType = 'hide' | 'freeText' | 'shortAnswer' | 'default'

const questionTypesWithAnswerArea = [
  'freeText',
  'simpleAnswer',
  'flexInput',
  'formatedInput',
]

export function transformQuestionToSatisfyAnswerAreaRules(
  question: Question,
  digital: boolean,
  inline: boolean,
  overrides?: AnswerAreaType
): subQuestionV2[] {
  const newContent = JSON.parse(JSON.stringify(question.content))
  const answerAreaTypes = getAnswerAreaTypes()
  return newContent.map((subQuestion: subQuestionV2) => {
    if (!digital && overrides && overrides[subQuestion.id] !== undefined) {
      // There is an answer area override, this overrides all other settings
      subQuestion.data.answerArea =
        answerAreaTypes[overrides[subQuestion.id]].settings
      return subQuestion
    }
    const action = digital
      ? answerAreaForQuestionInDigital(inline, subQuestion)
      : answerAreaForQuestionInPrint(inline, subQuestion)
    if (action === 'hide') {
      subQuestion.data.answerArea =
        answerAreaTypes[AnswerAreaCode.NONE].settings
      subQuestion.data.forcePaper = true
    } else if (action === 'freeText') {
      subQuestion.data.answerArea =
        answerAreaTypes[AnswerAreaCode.MOTIVATION_NORMAL].settings
      subQuestion.data.forcePaper = true
    } else if (action === 'shortAnswer') {
      subQuestion.data.answerArea =
        answerAreaTypes[AnswerAreaCode.ANSWER_STANDARD].settings
      subQuestion.data.forcePaper = true
    }
    return subQuestion
  })
}

function answerAreaForQuestionInDigital(
  inline: boolean,
  content: subQuestionV2
): MetaAnswerAreaType {
  if (content.type === 'freeText' && !inline) {
    return 'hide'
  }
  return 'default'
}

function answerAreaForQuestionInPrint(
  inline: boolean,
  content: subQuestionV2
): MetaAnswerAreaType {
  if (!questionTypesWithAnswerArea.includes(content.type)) {
    return 'default'
  }
  if (!inline) {
    return 'hide'
  }
  const customAnswerArea = questionHasCustomAnswerArea(
    content.type,
    content.data.answerArea
  )
  if (customAnswerArea) {
    return 'default'
  }
  if (inline) {
    if (content.type === 'freeText') {
      return 'freeText'
    }
    return 'shortAnswer'
  }
  return 'default'
}

function questionHasCustomAnswerArea(
  type: string,
  setting: answerArea | undefined | null
): boolean {
  if (!setting) {
    return false
  }
  if (
    type === 'freeText' &&
    (setting.answerRow !== 'none' ||
      setting.background !== 'none' ||
      setting.outline ||
      setting.size !== 'auto')
  ) {
    return true
  }
  if (
    ['simpleAnswer', 'flexInput', 'formatedInput'].includes(type) &&
    (setting.answerRow !== 'small' ||
      setting.background !== 'none' ||
      setting.outline ||
      setting.size !== 'auto')
  ) {
    return true
  }
  return false
}
