import { defineStore } from 'pinia'
import { merge } from 'ts-deepmerge'
import { insertInOrder } from '@/utils/difficulty'
import { registerEvent } from '@/utils/googleAnalytics'
import { delay } from '@/utils/delay'
import { reindexQuestion, getType } from '@/utils/reindexQuestion'
import {
  SAVE_DELAY,
  ExamType,
  ExamViewMode,
  ExamMovePosition,
  QuestionPointsMode,
  BrowserSecurity,
  ExamClient,
  DigitalToolsMode,
  TranslationType,
  GeogebraType,
  SpellCheckLanguage,
  ExamStatus,
  ExamAnswerArea,
  ExamPartStatusAfterSubmission,
  ExamPartStatus,
} from '@/constants'
import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'
import { getDateToYYYYMMDD } from '@/utils/geo'
import { ensureExamSettingsValid } from '@/utils/ensureExamSettingsValid'
import {
  getDefaultExamPartsSettings,
  getExamPartHeadingFromSettings,
} from '@/utils/getDefaultExamPartsSettings'
import type {
  DifficultyPoint,
  PointsSummaryMatrix,
  QuestionCriteriaWithPoints,
} from 'gauss-matrix'
import {
  abilityMatrix,
  subchapterMatrix,
  calculateExamMomentLimits,
} from 'gauss-matrix'
import { UserFacingError } from '@/utils/UserFacingError'
import { updateFieldByPath } from '@/utils/updateFieldByPath'
import { transformQuestionToSatisfyAnswerAreaRules } from '@/utils/questions/transformAnswerArea'

import ExamService from '@/services/ExamService'

type SubchapterSorting = {
  subchapterId: number
  globalSort: number
}

type PartSummaryData = {
  questionsCount: number
  calculatorAllowed?: boolean
}[]

export const BLANK_EXAM: Exam = {
  id: 0,
  name: '',
  language: 'en',
  category: '',
  type: ExamType.Exam,
  group: null,
  settings: {
    blockStudentAccess: [],
    limits: [],
    basic: {
      pointsMode: QuestionPointsMode.POINTS_TOTAL,
      date: getDateToYYYYMMDD(new Date()),
      duration: 0,
    },
    security: {
      allowedExamClient: ExamClient.NO_SEB,
      browserSecurity: BrowserSecurity.UNLOCK_MANUAL,
      randomizeQuestionOrder: false,
      coverAnswers: true,
    },
    studentExperience: {
      hideAvailablePoints: false,
      selfAssessmentAvailableAfterSubmission: false,
      automarkedResultsAfterSubmission: false,
    },
    teacherExperience: {
      anonymizeStudentNames: false,
    },
    languageTools: {
      spellCheck: SpellCheckLanguage.SWEDISH,
      textToSpeech: false,
      translate: TranslationType.WORD,
      dictionaries: [],
    },
    tools: {
      digitalToolsMode: DigitalToolsMode.PART_SETTINGS,
      drawingArea: false,
      geogebraType: GeogebraType.CLASSIC,
      geogebra: [],
      desmos: [],
      calculator: [],
      camera: false,
      audioRecording: false,
      formula: false,
      programming: false,
    },
    parts: {
      state: [ExamPartStatus.OPEN],
      partsAfterSubmission: ExamPartStatusAfterSubmission.READONLY,
    },
  },
  parts: [],
  subject: {
    id: 0,
    name: '',
  },
  course: {
    id: 0,
    name: '',
    subjectId: 0,
    curriculumId: 0,
    language: 'en',
    abilities: [],
    settings: {
      calculator: true,
      gradeThreshold: '',
      pointsMode: QuestionPointsMode.POINTS_TOTAL,
      displayCriteriasAsMatrixThreshold: 0,
      skipBlankAbilityRows: false,
    },
  },
  material: { id: 0, name: '', type: 'BOOK' },
  createdAt: '',
  updatedAt: '',
  exportedAt: '',
  status: ExamStatus.CLOSED,
  examKey: '',
  externalExamId: 0,
}

export const EMPTY_POINT_SUMMARY_MATRIX = [
  {
    given: {
      points: [0, 0, 0],
      rowTotal: 0,
    },
    available: {
      points: [0, 0, 0],
      rowTotal: 0,
    },
    name: '',
    colors: ['white', 'white', 'white'],
  },
] as PointsSummaryMatrix

export const useExamStore = defineStore('exam', {
  state: () => ({
    loadedExam: null as Exam | null,
    sorting: [] as SubchapterSorting[],
    viewMode: ExamViewMode.DIGITAL,
    dirty: false,
    literature: [] as Attachment[],
    students: [] as Student[],
    invalidParticipants: false,
    invalidPartStatus: false,
  }),
  getters: {
    exam(): Exam {
      if (!this.loadedExam) {
        // It is ok to load a blank exam, we expect the real exam to load momentarily
        return BLANK_EXAM
      }
      return this.loadedExam
    },
    basicSettings(): BasicSettings {
      return this.exam.settings.basic
    },
    settings(): ExamSettings {
      return this.exam.settings
    },
    groupStudents(): Student[] {
      return this.students
    },
    getViewMode(): ExamViewMode {
      return this.viewMode
    },
    questions(): Question[] {
      return this.exam.parts
        .map((part: ExamPart) => {
          const inline = part.settings.answerArea === ExamAnswerArea.INLINE
          return part.questions.map((question: Question) => {
            const override = this.exam.settings.answerOverrides?.[question.id]
            const content = transformQuestionToSatisfyAnswerAreaRules(
              question,
              true,
              inline,
              override
            )
            return { ...question, content }
          })
        })
        .flat()
    },
    questionsTotalCount(): number {
      // count all questions in all parts
      return (
        this.loadedExam?.parts?.reduce((acc, part) => {
          return acc + part.questions.length
        }, 0) || 0
      )
    },
    getQuestionsPerSubchapter(): Record<number, Record<number, number>> {
      // returns -> {240: {245: 4, 265: 1}, <another chapter id>: {<subchapter id>: <count>}}
      const questionsPerSubchapter: Record<
        number,
        Record<number, number>
      > = this.exam.parts.reduce(
        (acc, part) => {
          part.questions.forEach((question) => {
            if (!question.context.chapter || !question.context.subchapter) {
              // There can always be questions not assigned to any chapter or subchapter (e.g. from repetitionsmoment)
              return
            }
            const chapterId = question.context.chapter.id
            const subchapterId = question.context.subchapter.id
            if (!acc[chapterId]) {
              acc[chapterId] = {}
            }
            if (!acc[chapterId][subchapterId]) {
              acc[chapterId][subchapterId] = 0
            }
            acc[chapterId][subchapterId]++
          })
          return acc
        },
        {} as Record<number, Record<number, number>>
      )
      return questionsPerSubchapter
    },
    hasExam(): boolean {
      return !!this.loadedExam?.id
    },
    hasAttachments(): boolean {
      return this.literature.length > 0
    },
    hasExamDraft(): boolean {
      return !!this.loadedExam && !this.exam.id && !!this.exam.material?.id
    },
    isExamMode(): boolean {
      return this.hasExam || this.hasExamDraft
    },
    hasUnsavedChanges(): boolean {
      return this.dirty
    },
    courseHasCalculator(): boolean {
      return !!this.exam.course.settings?.calculator // prevent crash when refreshing in draft mode w/o courseId
    },
    getExamDate(): Date {
      return this.exam.settings.basic.date
        ? new Date(this.exam.settings.basic.date)
        : new Date()
    },
    isMissingHighlyRecommendedSettings(): boolean {
      return !this.exam.group?.id || this.hasExamDraft
    },
    isFullyAutoMarked(): boolean {
      return this.exam.parts.every((part) =>
        part.questions.every((question) => question.context.autocorrect)
      )
    },
    getCriteriaWithPoints(): QuestionCriteriaWithPoints[] {
      if (this.exam.parts.length === 0) {
        return []
      }
      const questions = this.exam.parts.flatMap((p) => p.questions)
      if (questions.length === 0) {
        return []
      }
      return questions.flatMap((q: Question) => {
        return q.criterias.map((c): QuestionCriteriaWithPoints => {
          const givenPoints = [0, 0, 0] as DifficultyPoint
          const availablePoints = [0, 0, 0] as DifficultyPoint
          givenPoints[c.pointIndex]++
          availablePoints[c.pointIndex]++
          return { ...c, givenPoints, availablePoints }
        })
      })
    },
    getSubchapterPointsSummaryMatrix(): PointsSummaryMatrix {
      if (this.exam.parts.length === 0) {
        return EMPTY_POINT_SUMMARY_MATRIX
      }
      const questions = this.exam.parts.flatMap((p) => p.questions)
      if (questions.length === 0) {
        return EMPTY_POINT_SUMMARY_MATRIX
      }
      const criterias = this.getCriteriaWithPoints
      const momentLimits = calculateExamMomentLimits(criterias, this.sorting)

      const matrix = subchapterMatrix(criterias, momentLimits, this.sorting)

      if (matrix?.length === 0) {
        return EMPTY_POINT_SUMMARY_MATRIX
      }

      return matrix
    },
    getAbilityPointsSummaryMatrix(): PointsSummaryMatrix {
      if (
        this.exam.parts.length === 0 ||
        this.exam.course.abilities.length === 0 ||
        this.exam.settings.limits.length === 0 ||
        !this.exam.settings.connectedAbilities
      ) {
        return EMPTY_POINT_SUMMARY_MATRIX
      }
      const questions = this.exam.parts.flatMap((p) => p.questions)
      if (questions.length === 0) {
        return EMPTY_POINT_SUMMARY_MATRIX
      }
      const criterias = this.getCriteriaWithPoints

      const matrix = abilityMatrix(
        criterias,
        this.exam.course.abilities,
        this.exam.settings.limits,
        this.exam.settings.connectedAbilities,
        this.exam.course.settings.skipBlankAbilityRows
      )

      if (matrix?.length === 0) {
        // FIXME: This happens when we change course in exam editor
        return EMPTY_POINT_SUMMARY_MATRIX
      }

      return matrix
    },
    getPartsSummaryData(): PartSummaryData {
      return (
        this.exam.parts?.map((part) => {
          const calculatorAllowed = this.courseHasCalculator
            ? part.settings.calculatorAllowed
            : undefined
          return {
            questionsCount: part.questions.length,
            ...(part.settings.calculatorAllowed !== undefined && {
              calculatorAllowed,
            }),
          }
        }) || []
      )
    },
    getQuestionsWithStudentAccess(): Question[] | [] {
      if (this.exam.parts.length === 0) {
        return []
      }
      return this.exam.parts
        .flatMap((part) => part.questions)
        .filter(
          (question) =>
            question.context.studentAccess === true &&
            !this.exam.settings.blockStudentAccess.includes(question.id)
        )
    },
    hasGroup(): boolean {
      return !!this.loadedExam?.group?.id
    },
    hasParticipants(): boolean {
      return (
        !!this.loadedExam?.group?.id &&
        this.loadedExam?.group?.numStudents > 0 &&
        !this.invalidParticipants
      )
    },
  },
  actions: {
    async getExamFromExamService(
      examId: number,
      anonymous = false
    ): Promise<Exam> {
      const globalStore = useGlobalStore()
      const exam = await ExamService.getExamById(examId, anonymous)
      globalStore.setQuestionLanguage(exam.language)
      this.ensureExamValid(exam)
      return exam
    },
    async getStudentExamFromExamService(
      examId: number,
      anonymous = false
    ): Promise<Exam> {
      const globalStore = useGlobalStore()
      const exam = await ExamService.getStudentExamById(examId, anonymous)
      globalStore.setQuestionLanguage(exam.language)
      this.ensureExamValid(exam)
      return exam
    },
    async loadExamByUUID(uuid: string) {
      const globalStore = useGlobalStore()
      const exam = await ExamService.getExamByUUId(uuid)
      globalStore.setQuestionLanguage(exam.language)
      this.ensureExamValid(exam)
      this.loadedExam = exam
    },
    async getExamFromFacade(examId: number, anonymous = false): Promise<Exam> {
      const globalStore = useGlobalStore()
      const userStore = useUserStore()
      const exam = (await userStore.facade.getExamById(
        examId,
        anonymous
      )) as Exam
      globalStore.setQuestionLanguage(exam.language)
      if (exam.course?.id) {
        // if the exam is loaded from ExamService (KM 2.0) then loadFullCourse is not needed,
        // if it is loaded from the facade, potentially KM 1.0 then it is needed
        exam.course = await this.loadFullCourse(exam.course.id)
      }
      this.ensureExamValid(exam)
      return exam
    },
    async getSortingFromFacade(
      materialId: number | string
    ): Promise<SubchapterSorting[]> {
      const userStore = useUserStore()
      return await userStore.facade.bookSorting(materialId)
    },
    async getPreferredBook() {
      const userStore = useUserStore()
      return await userStore.facade.getPreferredBook(
        this.exam.course.id,
        this.exam.group?.id
      )
    },
    ensureExamValid(exam: Exam) {
      if (!exam.settings) {
        exam.settings = {} as ExamSettings
      }

      if (!exam?.settings?.basic?.pointsMode) {
        exam.settings = {
          ...exam.settings,
          basic: {
            ...exam.settings.basic,
            pointsMode: exam.course.settings.pointsMode,
          },
        }
      }

      // Defence against old exams setting
      if (
        exam.settings.basic?.pointsMode &&
        !(exam.settings.basic.pointsMode in QuestionPointsMode)
      ) {
        // handle cases where pointsMode was MATRIX_DIFFICULTY, MATRIX_ABILITY or POINTS_ECA
        exam.settings.basic.pointsMode = QuestionPointsMode.POINTS_DIFFICULTY
      }

      exam.settings = ensureExamSettingsValid(
        exam.settings,
        BLANK_EXAM.settings
      )

      if (!exam.parts) {
        exam.parts = []
      }
    },
    getDefaultExamSettings(pointsMode?: QuestionPointsMode): ExamSettings {
      const { km } = useUserStore()
      const duration = km ? 40 : 0
      const settings = BLANK_EXAM.settings
      settings.basic.duration = duration

      if (pointsMode) {
        settings.basic.pointsMode = pointsMode
      }
      return JSON.parse(JSON.stringify(settings))
    },
    async loadStudentExam(examId: number) {
      this.dirty = false
      this.loadedExam = await this.getStudentExamFromExamService(examId, false)

      if (!this.loadedExam.settings.basic.pointsMode) {
        this.loadedExam.settings.basic.pointsMode =
          this.loadedExam.course.settings.pointsMode
      }

      if (this.loadedExam.material?.id) {
        this.sorting = await this.getSortingFromFacade(
          this.loadedExam.material.id
        )
      }
      // TODO: await this.loadLiterature() Literature is not loaded for students
      this.ensureValidLimits()
    },
    async loadExam(examId: number, anonymous = false) {
      this.dirty = false
      this.loadedExam = await this.getExamFromFacade(examId, anonymous)

      if (!this.loadedExam.settings.basic.pointsMode) {
        this.loadedExam.settings.basic.pointsMode =
          this.loadedExam.course.settings.pointsMode
      }

      if (this.loadedExam.material?.id) {
        this.sorting = await this.getSortingFromFacade(
          this.loadedExam.material.id
        )
      }
      await this.loadLiterature()
      this.ensureValidLimits()
    },
    async ensureValidLimits() {
      if (
        !this.loadedExam?.settings?.limits ||
        !this.loadedExam?.settings?.connectedAbilities
      ) {
        this.dirty = true
        await this.updateLimits()
        return
      }
      const connectedAbilities = this.loadedExam.settings.connectedAbilities
      // gather data on all questions in the exam
      const criterias = this.loadedExam.parts
        .flatMap((part) => part.questions)
        .flatMap((question) => question.criterias)
      const criteriaSummary = criterias.reduce((acc, criteria) => {
        const abilityIndex =
          criteria.abilityKey in connectedAbilities
            ? connectedAbilities[criteria.abilityKey]
            : -1
        acc[abilityIndex] = acc[abilityIndex] || {
          e: 0,
          c: 0,
          a: 0,
        }
        acc[abilityIndex][criteria.pointType]++
        return acc
      }, {} as any)

      // limits without total row
      const limits = this.loadedExam.settings.limits.slice(
        0,
        this.loadedExam.settings.limits.length - 1
      )

      const limitSummary = limits.reduce((acc, limit, index) => {
        acc[index] = {
          e: limit[0].levelPoints,
          c: limit[1].levelPoints,
          a: limit[2].levelPoints,
        }
        return acc
      }, {} as any)
      // compary the two
      const invalidLimits = Object.keys(limitSummary).filter((abilityIndex) => {
        if (!criteriaSummary[abilityIndex]) {
          // No points in this ability, so no limits should be set
          return !(
            limitSummary[abilityIndex].e === 0 &&
            limitSummary[abilityIndex].c === 0 &&
            limitSummary[abilityIndex].a === 0
          )
        }
        if (!limitSummary[abilityIndex]) {
          return true
        }
        return (
          limitSummary[abilityIndex].e !== criteriaSummary[abilityIndex].e ||
          limitSummary[abilityIndex].c !== criteriaSummary[abilityIndex].c ||
          limitSummary[abilityIndex].a !== criteriaSummary[abilityIndex].a
        )
      })
      if (invalidLimits.length > 0) {
        this.dirty = true
        await this.updateLimits()
      }
    },
    getAllQuestionIds(): number[] {
      return this.exam.parts.flatMap((part) => part.questions.map((q) => q.id))
    },
    setExamDate(newDate: Date | null) {
      if (!newDate) {
        this.updateBasicSettings({
          date: '',
        })
        return
      }
      this.updateBasicSettings({
        date: getDateToYYYYMMDD(newDate),
      })
    },

    setExported(externalExamId: number, exportedAt: string, examKey: string) {
      this.setExamExportedAt(exportedAt)
      this.exam.externalExamId = externalExamId
      this.exam.examKey = examKey
    },
    setExamType(type: ExamType) {
      this.dirty = true
      this.exam.type = type
    },
    updateBasicSettings(basic: BasicSettings | Partial<BasicSettings>) {
      this.dirty = true
      this.exam.settings.basic = { ...this.exam.settings.basic, ...basic }
    },
    updateSettings(field: string[], value: any) {
      updateFieldByPath(this.exam.settings, field, value)

      // Mark the exam as dirty
      this.dirty = true
    },
    async loadFullCourse(courseId: number): Promise<ExamFullCourse> {
      const userStore = useUserStore()
      return await userStore.facade.getCourse(courseId)
    },
    async setExamCourse(courseId: number) {
      this.dirty = true
      this.exam.course = await this.loadFullCourse(courseId)
    },
    async loadExamFromObject(exam: ExamForLoading) {
      const globalStore = useGlobalStore()
      this.dirty = true
      const examCopy = JSON.parse(JSON.stringify(exam))
      if (examCopy.course?.id) {
        examCopy.course = await this.loadFullCourse(examCopy.course.id)
        examCopy.settings.basic.pointsMode = examCopy.course.settings.pointsMode
      }

      this.loadedExam = examCopy

      if (examCopy.group?.id) {
        this.setExamGroup(examCopy.group.id, examCopy.group.name)
      }

      globalStore.setQuestionLanguage(this.loadedExam?.course?.language || 'en')
      if (this.loadedExam?.material?.id) {
        this.sorting = await this.getSortingFromFacade(
          this.loadedExam.material.id
        )
        await this.updateLimits()
      }
      if (this.loadedExam?.parts.length === 0) {
        // add default parts if no parts are present
        const { km } = useUserStore()
        // Only create two parts in KM because the Maths Exam.net integration
        // does not work well with multiple parts right now
        const createCalculatorParts =
          this.loadedExam.course?.settings?.calculator && km
        if (createCalculatorParts) {
          this.loadedExam.parts.push({
            questions: [],
            questionsEdited: {},
            settings: getDefaultExamPartsSettings(false),
          })
          this.loadedExam.parts.push({
            questions: [],
            questionsEdited: {},
            settings: getDefaultExamPartsSettings(true),
          })
        } else {
          this.loadedExam.parts.push({
            questions: [],
            questionsEdited: {},
            settings: getDefaultExamPartsSettings(false),
          })
        }
      }
    },
    async saveExam(asNew = false, keep = false) {
      if (!this.loadedExam) {
        throw new Error('No exam loaded')
      }

      if (this.invalidParticipants) {
        throw new UserFacingError(
          {},
          'Invalid exam participants',
          false,
          'exam.invalidParticipants'
        )
      }

      if (this.invalidPartStatus) {
        throw new UserFacingError(
          {},
          'Invalid exam part status at start',
          false,
          'exam.invalidPartStatus'
        )
      }

      if (this.questionsTotalCount === 0) {
        throw new UserFacingError(
          {},
          'No questions in exam',
          false,
          'exam.invalidQuestions'
        )
      }

      registerEvent('Save', 'Exam', this.loadedExam.name)
      const userStore = useUserStore()
      await delay(SAVE_DELAY)

      if (!this.loadedExam.id || asNew) {
        const copiedFrom = this.loadedExam.id

        const copiedExam = JSON.parse(JSON.stringify(this.loadedExam))

        delete copiedExam.id
        if (keep) {
          // from sneak peek
          await userStore.facade.createExam(copiedExam, copiedFrom)
        } else {
          this.loadedExam = await userStore.facade.createExam(
            copiedExam,
            copiedFrom
          )
        }
      } else {
        const { swappedQuestions } = (await userStore.facade.saveExam(
          this.loadedExam
        )) as Exam & { swappedQuestions: number[] }
        if (swappedQuestions && swappedQuestions.length > 0) {
          // used in KM 1.0
          // FIXME we can use swapped questions and replace them in the exam
          // however for now we just reload the exam
          this.loadedExam = await this.getExamFromFacade(this.loadedExam.id)
        }
      }
      this.dirty = false
    },
    async saveExamSettings(): Promise<Exam> {
      if (!this.loadedExam) {
        throw new Error('No exam loaded')
      }

      if (this.invalidParticipants) {
        throw new UserFacingError(
          {},
          'Invalid exam participants',
          false,
          'exam.invalidParticipants'
        )
      }

      if (this.invalidPartStatus) {
        throw new UserFacingError(
          {},
          'Invalid exam part status at start',
          false,
          'exam.invalidPartStatus'
        )
      }
      registerEvent('Save', 'ExamSettings', this.loadedExam.name)
      await delay(SAVE_DELAY)

      const exam = JSON.parse(JSON.stringify(this.loadedExam))
      delete exam.parts
      const response = await ExamService.saveExam(exam)
      this.dirty = false
      return response
    },
    async saveExamSettingsViaFacade() {
      if (!this.loadedExam) {
        throw new Error('No exam loaded')
      }

      if (this.invalidParticipants) {
        throw new UserFacingError(
          {},
          'Invalid exam participants',
          false,
          'exam.invalidParticipants'
        )
      }

      if (this.invalidPartStatus) {
        throw new UserFacingError(
          {},
          'Invalid exam part status at start',
          false,
          'exam.invalidPartStatus'
        )
      }
      registerEvent('Save', 'ExamSettings', this.loadedExam.name)
      const userStore = useUserStore()
      await delay(SAVE_DELAY)

      const exam = JSON.parse(JSON.stringify(this.loadedExam))
      delete exam.parts
      const response = await userStore.facade.saveExam(exam)
      this.dirty = false
      return response
    },
    setExamStatus(status: ExamStatus) {
      this.dirty = true
      this.exam.status = status
    },
    setExamExportedAt(date: string) {
      this.exam.exportedAt = date
    },
    setDirtyState(dirty: boolean) {
      this.dirty = dirty
    },
    setViewMode(mode: ExamViewMode) {
      this.viewMode = mode
    },
    setExamName(name: string) {
      this.dirty = true
      this.exam.name = name
    },
    setExamMaterial(materialId: number | string, materialName: string) {
      this.exam.material = { id: materialId, name: materialName, type: 'BOOK' }
    },
    setExamStudents(students: Student[]) {
      this.students = students
    },
    setExamGroup(id: number, name: string) {
      this.dirty = true
      this.exam.group = {
        id,
        name,
        participants: [],
        numStudents: 0,
      }
    },
    setParticipants(participants: number[]) {
      this.dirty = true
      if (!this.exam.group?.id) {
        throw new Error('No group found')
      }
      this.exam.group.participants = participants

      if (this.invalidParticipants) {
        this.exam.group.numStudents = 0
      } else if (participants.length === 0) {
        this.exam.group.numStudents = this.students.length
      } else {
        this.exam.group.numStudents = participants.length
      }
    },
    setInvalidParticipants(invalid: boolean) {
      this.invalidParticipants = invalid
    },
    setInvalidPartStatus(invalid: boolean) {
      this.invalidPartStatus = invalid
    },
    setCoverSheet(settings: Partial<CoverSheetSettings>) {
      this.dirty = true
      this.exam.settings.coverSheet = merge(
        this.exam.settings.coverSheet || { enabled: false },
        settings
      ) as CoverSheetSettings
    },
    addExamPart(part: ExamPart) {
      this.dirty = true
      const partDeepCopy = JSON.parse(JSON.stringify(part))
      this.exam.parts.push(partDeepCopy)
      const partIndex = this.exam.parts.length - 1

      return partIndex
    },
    getExamPartByIndex(partIndex: number): ExamPart {
      return this.exam.parts[partIndex] || ({} as ExamPart)
    },
    getExamPartIndexByQuestionId(questionId: number): number {
      return this.exam.parts.findIndex((part) => {
        return part.questions.some((question) => question.id === questionId)
      })
    },
    getConsecutiveQuestionNumber(questionId: number): number {
      const partIndex = this.getExamPartIndexByQuestionId(questionId)
      if (partIndex === -1) {
        return -1
      }

      const questionIndex = this.exam.parts[partIndex].questions.findIndex(
        (question) => question.id === questionId
      )
      if (partIndex === -1) {
        return -1
      }

      const lastQuestionIndexInPreviousPart = this.exam.parts
        .map((part) => part.questions.length)
        .slice(0, partIndex)
        .reduce((acc, partQuestionsCount) => acc + partQuestionsCount, 0)
      return lastQuestionIndexInPreviousPart + questionIndex + 1
    },
    async removeExamPart(partIndex: number) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true

      const blockStudentAccess = this.exam.settings.blockStudentAccess
      this.exam.parts[partIndex].questions.forEach((question) => {
        // remove answer override for questionId if exists
        if (
          this.exam.settings.answerOverrides &&
          this.exam.settings.answerOverrides[question.id]
        ) {
          delete this.exam.settings.answerOverrides[question.id]
        }
        // remove questionId from blockStudentAccess if exists
        if (blockStudentAccess.includes(question.id)) {
          this.exam.settings.blockStudentAccess = blockStudentAccess.filter(
            (a: number) => a !== question.id
          )
        }
      })
      this.exam.parts.splice(partIndex, 1)
      await this.updateLimits()
    },
    getExamPartHeading(partIndex: number, tt: any) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      const settings = this.getExamPartSettings(partIndex) as ExamPartSettings
      if (!settings) {
        console.error('Exam part settings not found')
        return
      }
      const { km } = useUserStore()
      return getExamPartHeadingFromSettings(
        km ?? false,
        this.exam.parts.length,
        partIndex,
        settings,
        this.courseHasCalculator,
        tt
      )
    },
    getExamPartSettings(partIndex: number) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      return this.exam.parts[partIndex].settings
    },
    setExamPartSettings(partIndex: number, settings: ExamPartSettings) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true
      const settingsDeepCopy = JSON.parse(JSON.stringify(settings))
      this.exam.parts[partIndex].settings = settingsDeepCopy
    },
    updateExamPartSettings(
      partIndex: number,
      settings: Partial<ExamPartSettings>
    ) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true
      this.exam.parts[partIndex].settings = merge(
        this.exam.parts[partIndex].settings,
        settings
      ) as ExamPartSettings
    },
    partLocked(partIndex: number): { next: boolean; current: boolean } {
      if (
        partIndex === this.exam.parts.length - 1 ||
        !this.courseHasCalculator
      ) {
        return { current: false, next: false }
      }
      const currentPartCalculatorAllowed =
        this.exam.parts[partIndex].settings.calculatorAllowed
      const nextPartCalculatorAllowed =
        this.exam.parts[partIndex + 1].settings.calculatorAllowed
      return {
        current: currentPartCalculatorAllowed && !nextPartCalculatorAllowed,
        next: !currentPartCalculatorAllowed && nextPartCalculatorAllowed,
      }
    },
    getAutoCorrectCountForExamPart(partIndex: number) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return { countOn: 0, countOff: 0 }
      }

      let autocorrect = 0
      let noAutocorrect = 0
      this.exam.parts[partIndex].questions.forEach((question: Question) => {
        if (question.context.autocorrect) {
          autocorrect++
        } else {
          noAutocorrect++
        }
      })
      return { countOn: autocorrect, countOff: noAutocorrect }
    },
    toggleBlockedStudentAccess(questionId: number) {
      this.dirty = true
      const blockStudentAccess = this.exam.settings.blockStudentAccess
      if (blockStudentAccess.includes(questionId)) {
        this.exam.settings.blockStudentAccess = blockStudentAccess.filter(
          (a: number) => a !== questionId
        )
      } else {
        this.exam.settings.blockStudentAccess.push(questionId)
      }
    },
    async addExamQuestion(partIndex: number, question: Question) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true
      const part = this.exam.parts[partIndex]
      const questionCopy = JSON.parse(JSON.stringify(question))
      insertInOrder(part.questions, questionCopy)
      await this.updateLimits()
      this.updateQuestionChapter(partIndex, questionCopy) // when we add questions from different books in the same course
      registerEvent('Add', 'Question', String(question.id))
    },
    async addExamQuestionPromise(
      partIndex: number,
      questionPromise: QuestionPromise
    ) {
      if (!this.exam.parts[partIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true
      const part = this.exam.parts[partIndex]
      const question = await questionPromise.promiseRetriever()
      insertInOrder(part.questions, question)
      await this.updateLimits()
    },
    moveExamQuestionToPartInOrder(questionId: number, newPartIndex: number) {
      if (!this.exam.parts[newPartIndex]) {
        console.error('Exam part not found')
        return
      }
      this.dirty = true
      const originPartIndex = this.getExamPartIndexByQuestionId(questionId)
      const questionIndex = this.exam.parts[
        originPartIndex
      ].questions.findIndex((question) => question.id === questionId)
      const question = this.exam.parts[originPartIndex].questions.splice(
        questionIndex,
        1
      )[0]
      insertInOrder(this.exam.parts[newPartIndex].questions, question)
    },
    moveExamQuestion(
      fromPartIndex: number,
      toPartIndex: number,
      fromQuestionIndex: number,
      toQuestionIndex: number,
      position: string
    ) {
      if (
        fromPartIndex === toPartIndex &&
        fromQuestionIndex === toQuestionIndex
      ) {
        return
      }

      this.dirty = true

      // extract question from the old position
      const question = this.exam.parts[fromPartIndex].questions.splice(
        fromQuestionIndex,
        1
      )[0]

      // moving within the same part
      if (fromPartIndex === toPartIndex) {
        if (position === ExamMovePosition.BEFORE) {
          // moving down
          if (toQuestionIndex > fromQuestionIndex) {
            toQuestionIndex--
          }
        } else if (position === ExamMovePosition.AFTER) {
          // moving up
          if (toQuestionIndex < fromQuestionIndex) {
            toQuestionIndex++
          }
        }
      } else {
        // moving to another part
        if (position === ExamMovePosition.AFTER) {
          toQuestionIndex++
        }
      }

      // insert question into a new position
      this.exam.parts[toPartIndex].questions.splice(
        toQuestionIndex,
        0,
        question
      )
    },
    moveExamPart(fromIndex: number, toIndex: number, position: string) {
      if (fromIndex === toIndex) {
        return
      }

      this.dirty = true

      // extract part from the old position
      const part = this.exam.parts.splice(fromIndex, 1)[0]

      if (position === ExamMovePosition.BEFORE) {
        // moving down
        if (toIndex > fromIndex) {
          toIndex--
        }
      } else if (position === ExamMovePosition.AFTER) {
        // moving up
        if (toIndex < fromIndex) {
          toIndex++
        }
      }

      // insert part into a new position
      this.exam.parts.splice(toIndex, 0, part)
    },
    async removeExamQuestion(questionId: number) {
      this.dirty = true
      this.exam.parts.forEach((part) => {
        part.questions = part.questions.filter(
          (question) => question.id !== questionId
        )
      })

      // remove questionId from blockStudentAccess if exists
      const blockStudentAccess = this.exam.settings.blockStudentAccess
      if (blockStudentAccess.includes(questionId)) {
        this.exam.settings.blockStudentAccess = blockStudentAccess.filter(
          (a: number) => a !== questionId
        )
      }

      // remove answer override for questionId if exists
      if (
        this.exam.settings.answerOverrides &&
        this.exam.settings.answerOverrides[questionId]
      ) {
        delete this.exam.settings.answerOverrides[questionId]
      }
      await this.updateLimits()
    },
    async removeSubQuestion(
      partIndex: number,
      questionId: number,
      subIndex: number
    ) {
      this.dirty = true

      const questionIndex = this.exam.parts[partIndex].questions.findIndex(
        (q) => q.id === questionId
      )

      const numberingType = getType(
        this.exam.parts[partIndex].questions[questionIndex].content.filter(
          (subq) => subq.number !== '' || subq.type !== 'informationBlock'
        )[0].number
      )

      const subQuestionId =
        this.exam.parts[partIndex].questions[questionIndex].content[subIndex].id

      if (
        this.exam.parts[partIndex].questions[questionIndex].metadata
          ?.subQuestionFingerprint
      ) {
        if (
          !this.exam.parts[partIndex].questions[questionIndex].metadata
            .subQuestionOriginalIndex
        ) {
          // First time a subquestion is deleted, create a basic list of indexes
          // [0, 1, 2, 3] one for each subquestion
          this.exam.parts[partIndex].questions[
            questionIndex
          ].metadata.subQuestionOriginalIndex = this.exam.parts[
            partIndex
          ].questions[questionIndex].content.map((_sq, index) => index)
        }
        // Get the original index of the subquestion
        // First time 3 -> 3 but next time 3 might give 2
        const originalIndex =
          this.exam.parts[partIndex].questions[questionIndex].metadata
            .subQuestionOriginalIndex[subIndex]
        // Remove the deleted question from from the original index array
        this.exam.parts[partIndex].questions[
          questionIndex
        ].metadata.subQuestionOriginalIndex.splice(subIndex, 1)
        // Mark the subquestion at originalIndex as removed
        this.exam.parts[partIndex].questions[
          questionIndex
        ].metadata.subQuestionFingerprint[originalIndex] = false
      }

      // remove subquestion
      this.exam.parts[partIndex].questions[questionIndex].content.splice(
        subIndex,
        1
      )

      // assign new number( a), b), c), 1., 2., 3., I, II, III ),
      const question = this.exam.parts[partIndex].questions.find(
        (q) => q.id === questionId
      )
      if (question) {
        reindexQuestion(question.content, numberingType)
      }

      this.updateEditedQuestions(questionId)

      // remove answer override for subQuestionId if it exists
      if (
        this.exam.settings.answerOverrides &&
        this.exam.settings.answerOverrides[questionId] &&
        this.exam.settings.answerOverrides[questionId][subQuestionId]
      ) {
        delete this.exam.settings.answerOverrides[questionId][subQuestionId]
      }
      await this.updateLimits()
    },
    async updateEditedQuestions(questionId: number) {
      this.dirty = true

      const partIndex = this.getExamPartIndexByQuestionId(questionId)

      this.exam.parts[partIndex].questionsEdited = {
        ...this.exam.parts[partIndex].questionsEdited,
        [questionId]: true,
      }

      const question = this.exam.parts[partIndex].questions.find(
        (q) => q.id === questionId
      ) as Question

      const userStore = useUserStore()
      await userStore.facade.refreshQuestionCriterias(
        this.exam.material.id,
        question
      )
      await this.updateLimits()
    },
    async updateLimits() {
      const userStore = useUserStore()
      const { limits, connectedAbilities } =
        await userStore.facade.getDefaultLimits(this.exam)
      this.exam.settings.limits = limits
      this.exam.settings.connectedAbilities = connectedAbilities
      this.exam.settings.defaultLimits = true
    },
    updateQuestionsContext() {
      // needed when we change exam book (material) from material picker
      this.exam.parts.forEach((part, partIndex) => {
        part.questions.forEach((q) => {
          this.updateQuestionChapter(partIndex, q)
        })
      })
    },
    updateQuestionChapter(partIndex: number, q: Question) {
      // matarial.id is string in km and number in gauss so we need to compare them as strings
      const location = q.context.usedIn?.find(
        (location) => String(location.book.id) === String(this.exam.material.id)
      )
      if (location) {
        const question = this.exam.parts[partIndex].questions.find(
          (question) => question.id === q.id
        )
        if (question) {
          question.context.chapter = location.chapter
          question.context.subchapter = location.subchapter
          question.context.course = location.course
        }
      }
    },
    updateAnswerOverrides(questionId: number, subQuestionType: AnswerAreaType) {
      this.dirty = true
      this.exam.settings.answerOverrides = merge(
        this.exam.settings.answerOverrides || {},
        {
          [questionId]: subQuestionType,
        }
      )
    },
    deleteAnswerOverrides(questionId: number, subQuestionId: number) {
      this.dirty = true
      if (
        this.exam.settings.answerOverrides &&
        this.exam.settings.answerOverrides[questionId]
      ) {
        delete this.exam.settings.answerOverrides[questionId][subQuestionId]
        // delete also questionId if it is empty
        if (
          Object.keys(this.exam.settings.answerOverrides[questionId]).length ===
          0
        ) {
          delete this.exam.settings.answerOverrides[questionId]
        }
      }
    },
    getSubQuestionAnswerArea(questionId: number) {
      if (this.exam.settings.answerOverrides === undefined) {
        return undefined
      }
      return this.exam.settings.answerOverrides[questionId]
    },
    getQuestionsCountPerPart(partIndex: number): number {
      // count all questions in a part
      return this.exam.parts[partIndex].questions.length
    },
    isQuestionAlreadyInExam(questionId: number): boolean {
      if (!this.loadedExam) {
        return false
      }
      return this.exam.parts?.some((part) => {
        return part.questions.some((question) => question.id === questionId)
      })
    },
    async loadLiterature() {
      const attachments = this.exam.parts
        .flatMap((part) => part.questions)
        .flatMap((q) => q.attachments)
      const userStore = useUserStore()
      const literature =
        await userStore.facade.getAttachmentsContent(attachments)
      this.literature = literature
    },
    handleExternalEditOfQuestion(questionId: number) {
      if (!this.isQuestionAlreadyInExam(questionId)) {
        return
      }
      // check if question is already marked as edited
      const partIndex = this.getExamPartIndexByQuestionId(questionId)
      if (
        this.exam.parts[partIndex].questionsEdited &&
        this.exam.parts[partIndex].questionsEdited[questionId]
      ) {
        // When a question is edited externally from question bank we do not
        // update it if it has local edits
        return
      }
      this.reloadQuestion(questionId)
    },
    async reloadQuestion(questionId: number) {
      const userStore = useUserStore()
      const question = (await userStore.facade.getQuestion(
        questionId,
        null,
        this.exam.material?.id
      )) as Question
      const partIndex = this.getExamPartIndexByQuestionId(questionId)
      const questionIndex = this.exam.parts[partIndex].questions.findIndex(
        (question) => question.id === questionId
      )
      this.exam.parts[partIndex].questions.splice(questionIndex, 1, question)
      this.dirty = true
      return question
    },
    async replaceQuestion(questionId: number, newQuestionPrimaryId: number) {
      const userStore = useUserStore()
      const newQuestion = (await userStore.facade.getQuestionByPrimaryId(
        newQuestionPrimaryId,
        this.exam.material?.id
      )) as Question
      const partIndex = this.getExamPartIndexByQuestionId(questionId)
      const questionIndex = this.exam.parts[partIndex].questions.findIndex(
        (question) => question.id === questionId
      )
      this.exam.parts[partIndex].questions.splice(questionIndex, 1, newQuestion)
      this.dirty = true
      return newQuestion
    },
    // Only used when adding the first question in question bank
    addInitialQuestion(question: Question) {
      if (question.context.calculator) {
        this.addExamQuestion(this.exam.parts.length - 1, question)
      } else {
        this.addExamQuestion(0, question)
      }
    },
    canDetermineBestPartForQuestion(question: Question): number {
      if (!this.loadedExam) {
        return -1
      }
      if (this.loadedExam.parts.length === 1) {
        return 0
      }

      const calculatorAllowed = question.context.calculator

      // count parts with calculatorAllowed
      const partsWithSameSetting = this.loadedExam.parts.filter(
        (part) => part.settings.calculatorAllowed === calculatorAllowed
      ).length

      if (partsWithSameSetting !== 1) {
        return -1
      }

      // only one part with calculatorAllowed setting
      return this.loadedExam.parts.findIndex(
        (part) => part.settings.calculatorAllowed === calculatorAllowed
      )
    },
    clear() {
      this.dirty = false
      this.loadedExam = null
      this.sorting = []
      this.viewMode = ExamViewMode.DIGITAL
      this.literature = []
      this.students = []
      this.invalidParticipants = false
    },
  },
})
