import { ExamAnswerArea } from '@/constants'

function getExamPartSettingsHeading(
  km: boolean,
  tt: (key: string, params?: any) => string,
  numberOfParts: number,
  partIndex: number,
  partSettings: ExamPartSettings,
  courseHasCalculator: boolean
) {
  const hasSomeAnswerAreas = partSettings.answerArea === ExamAnswerArea.INLINE
  if (numberOfParts === 1 && hasSomeAnswerAreas) {
    return ''
  }
  const calculator = partSettings.calculatorAllowed

  let title = tt('exam.part.title', {
    index: String.fromCharCode(65 + partIndex),
  })
  title += ': '
  if (courseHasCalculator) {
    const text = calculator
      ? tt('exam.part.heading.digitalToolsAllowed')
      : tt('exam.part.heading.digitalToolsNotAllowed')
    title += text
    title += ' '
  }
  const answerInfo = hasSomeAnswerAreas
    ? tt('exam.part.heading.answerInline')
    : tt('exam.part.heading.answerSeparate')
  title += answerInfo
  if (km) {
    const style =
      'margin-left:-0px; padding: 3px 5px 2px 5px; border:solid 1px black;'
    return `<div style="${style}">${title}</div>
    <div class="km-paragraph">&nbsp;</div>`
  } else {
    return title
  }
}

function getExamPartHeadingFromSettings(
  km: boolean,
  numberOfParts: number,
  partIndex: number,
  settings: ExamPartSettings,
  courseHasCalculator: boolean,
  tt: any
) {
  const heading = settings.heading
  return heading !== undefined // empty string is a valid heading
    ? heading
    : getExamPartSettingsHeading(
        km || false,
        tt,
        numberOfParts,
        partIndex,
        settings,
        courseHasCalculator
      )
}

function getDefaultExamPartsSettings(calculator: boolean): ExamPartSettings {
  return {
    calculatorAllowed: calculator,
    answerArea: ExamAnswerArea.INLINE,
  }
}

export {
  getExamPartSettingsHeading,
  getDefaultExamPartsSettings,
  getExamPartHeadingFromSettings,
}
